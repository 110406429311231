import Vue from 'vue'
import App from './App.vue'
import store from './static/store'
import router from './static/router';
import ElementUI, {Loading} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'animate.css'
import './static/font/iconfont.css'
import axios from "axios"
import qs from 'qs'
import VueClipboard from "vue-clipboard2"
import AmapVue from 'vue-amap'

Vue.config.productionTip = false

Vue.use(ElementUI, {size: 'normal'});
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.use(VueClipboard)
Vue.use(AmapVue)
AmapVue.initAMapApiLoader({
  key: '15f3cec9fa0b0ba3a142cb7e33d362d6',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});

Vue.prototype._dateFormat = function (fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

Vue.prototype._handleException = function (showMsg, status, msg) {
  let message = '';
  let error = true;
  switch (status) {
    case 10000:
      error = false;
      break;
    case 0:
      message = "网络连接失败，请重试"
      break;
    case 403:
      message = "服务器拒绝访问"
      break;
    case 404:
      message = "请求地址错误"
      break;
    case 405:
      message = "请求方法不允许"
      break;
    case 500:
      message = "服务器错误"
      break;
    case 502:
      message = "请求超时"
      break;
    case -1:
      message = (msg == null ? '服务器错误' : msg)
      break;
    case 10001:
      message = (msg == null ? '登录失败' : msg)
      break;
    case 10002:
      message = (msg == null ? '未选择Game服务器' : msg)
      break
    case 10003:
      message = (msg == null ? '资源不存在' : msg)
      break;
    case 10004:
      message = (msg == null ? 'Game响应异常' : msg)
      break;
    case 10007:
      message = "操作无权限"
      break
    case 10008:
      message = "操作被拒绝"
      break
    default:
      message = "未知错误"
  }

  if (showMsg && error) {
    this.$message.error(message + (status === undefined || msg != null ? '' : (' ' + status)));
  }
  return error;
}

/**
 * 判断一个对象是否为逻辑上的空
 *
 * @param value
 * @returns {boolean|boolean}
 * @private
 */
Vue.prototype._isEmpty = function (value) {
  return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
  );
}

/**
 * 命令行请求
 *
 * @param command 命令行 String
 * @param batch
 * @param socketKey
 * @returns {Promise<*>}
 * @private
 */
Vue.prototype._requestCmd = async function (command, batch, socketKey) {
  return new Promise((resolve, reject) => {
    axios({
      url: store.state.host + '/non_pub/command/exec',
      method: 'post',
      responseType: "json",
      headers: {
        token: store.state.token,
        socketAddress: store.state.socketAddress
      },
      params: {
        command: command,
        batch: (batch == null ? false : batch),
        groupName: batch ? store.state.position[0] : '',
        channelName: batch ? store.state.position[1] : '',
        socketKey: batch ? socketKey : ''
      }
    }).then((res) => {
      let resData = res.data
      if (!this._handleException(false, resData.status, resData.msg)) {
        resolve(resData)
      } else {
        reject(resData)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

Vue.prototype._formatHtml = function (html) {
  return String(html)
      .replace(/&(?!\w+;)/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
}

let loading;

Vue.prototype._startLoading = function () {
  loading = Loading.service({
    lock: true,
    text: '加载中',
    background: 'rgba(0,0,0,0,7)'
  })
}

Vue.prototype._endLoading = function () {
  loading.close()
}

Vue.prototype._uuid = function () {
  let s = [];
  let hexDigits = "0123456789abcdefghijklmnopqrst";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join("");
}

/**
 * bytes转字符串 UTF-8格式
 *
 * @param utf8Bytes
 * @returns {string}
 * @private
 */
Vue.prototype._bytesToStr = function(utf8Bytes){
  let unicodeStr ="";
  for (let pos = 0; pos < utf8Bytes.length;){
    let flag= utf8Bytes[pos];
    let unicode = 0 ;
    if ((flag >>>7) === 0 ) {
      unicodeStr+= String.fromCharCode(utf8Bytes[pos]);
      pos += 1;

    } else if ((flag &0xFC) === 0xFC ){
      unicode = (utf8Bytes[pos] & 0x3) << 30;
      unicode |= (utf8Bytes[pos+1] & 0x3F) << 24;
      unicode |= (utf8Bytes[pos+2] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos+3] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos+4] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos+5] & 0x3F);
      unicodeStr+= String.fromCharCode(unicode) ;
      pos += 6;

    }else if ((flag &0xF8) === 0xF8 ){
      unicode = (utf8Bytes[pos] & 0x7) << 24;
      unicode |= (utf8Bytes[pos+1] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos+2] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos+3] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos+4] & 0x3F);
      unicodeStr+= String.fromCharCode(unicode) ;
      pos += 5;

    } else if ((flag &0xF0) === 0xF0 ){
      unicode = (utf8Bytes[pos] & 0xF) << 18;
      unicode |= (utf8Bytes[pos+1] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos+2] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos+3] & 0x3F);
      unicodeStr+= String.fromCharCode(unicode) ;
      pos += 4;

    } else if ((flag &0xE0) === 0xE0 ){
      unicode = (utf8Bytes[pos] & 0x1F) << 12;
      unicode |= (utf8Bytes[pos+1] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos+2] & 0x3F);
      unicodeStr+= String.fromCharCode(unicode) ;
      pos += 3;

    } else if ((flag &0xC0) === 0xC0 ){ //110
      unicode = (utf8Bytes[pos] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos+1] & 0x3F);
      unicodeStr+= String.fromCharCode(unicode) ;
      pos += 2;

    } else{
      unicodeStr+= String.fromCharCode(utf8Bytes[pos]);
      pos += 1;
    }
  }
  return unicodeStr;
}

/**
 * string转byte数组，UTF-8格式
 *
 * @param str
 * @returns {any[]}
 * @private
 */
Vue.prototype._strToBytes = function(str){
  let bytes = [];
  for (let i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i);
    let s = parseInt(c).toString(2);
    if(c >= parseInt("000080",16) && c <= parseInt("0007FF",16)){
      let af = "";
      for(let j = 0; j < (11 - s.length); j++){
        af += "0";
      }
      af += s;
      let n1 = parseInt("110" + af.substring(0,5),2);
      let n2 = parseInt("110" + af.substring(5),2);
      if(n1 > 127) n1 -= 256;
      if(n2 > 127) n2 -= 256;
      bytes.push(n1);
      bytes.push(n2);
    }else if(c >= parseInt("000800",16) && c <= parseInt("00FFFF",16)){
      let af = "";
      for(let j = 0; j < (16 - s.length); j++){
        af += "0";
      }
      af += s;
      let n1 = parseInt("1110" + af.substring(0,4),2);
      let n2 = parseInt("10" + af.substring(4,10),2);
      let n3 = parseInt("10" + af.substring(10),2);
      if(n1 > 127) n1 -= 256;
      if(n2 > 127) n2 -= 256;
      if(n3 > 127) n3 -= 256;
      bytes.push(n1);
      bytes.push(n2);
      bytes.push(n3);
    }else if(c >= parseInt("010000",16) && c <= parseInt("10FFFF",16)){
      let af = "";
      for(let j = 0; j < (21 - s.length); j++){
        af += "0";
      }
      af += s;
      let n1 = parseInt("11110" + af.substring(0,3),2);
      let n2 = parseInt("10" + af.substring(3,9),2);
      let n3 = parseInt("10" + af.substring(9,15),2);
      let n4 = parseInt("10" + af.substring(15),2);
      if(n1 > 127) n1 -= 256;
      if(n2 > 127) n2 -= 256;
      if(n3 > 127) n3 -= 256;
      if(n4 > 127) n4 -= 256;
      bytes.push(n1);
      bytes.push(n2);
      bytes.push(n3);
      bytes.push(n4);
    }else{
      bytes.push(c & 0xff);
    }
  }
  return bytes;
}

Vue.prototype._strToBytesStr = function(str){
  let bytes = this._strToBytes(str)
  return bytes.toString()
}

Vue.prototype._handleException = function (alter, status, msg){
  let message = ''
  let error = true;
  switch (status){
    case 10000:
      error = false
      break
    case 0:
      message = "网络连接失败，请重试"
      break
    case -1:
      message = "服务器错误 " + msg
      break
    case 10001:
      message = "登陆失败"
      break
    default:
      message = "未知错误"
  }
  if (alter && error){
    this._notifyError(message)
  }

  return {
    status:status,
    error:error,
    msg: message,
    rspMsg: msg
  }
}

Vue.prototype._alertError = function (msg){
  this.$alert(msg, {
    confirmButtonText: '确定',
    type: 'error'
  });
}

Vue.prototype._notifySuccess = function (msg){
  this.$message({
    message: msg,
    type: 'success'
  });
}

Vue.prototype._notifyError = function (msg){
  this.$message.error(msg);
}

/**
 * 路由切换拦截，每次切换路由都判断登录状态
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.name === "userCenter") {
    if (store.state.isAuthenticated){
      next({name:'userInfo'})
    }else{
      next({name:'login'})
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
