import Vue from 'vue';
import Router from 'vue-router';

import Index from "../components/Index";
import Login from "@/components/user/Login";
import Product from "@/components/Product";
import UserCenter from "@/components/UserCenter";
import Cooperation from "@/components/Cooperation";
import About from "@/components/About";
import Guardianship from "@/components/Guardianship";
import UserInfo from "@/components/user/UserInfo";
import Editor from "@/components/Editor";
import PayCenter from "@/components/PayCenter";

Vue.use(Router);

const WEB_CONF = {
    TITLE: '杭州烈酷科技有限公司',
    TITLE_SIMP: '——烈酷科技'
}

/**
 * 定义路由
 */
export default new Router({
    mode:'hash',
    routes: [
        {path: '/', redirect: '/index', meta: {title: WEB_CONF.TITLE}},
        {path: '/index', name: "index", component: Index, meta: {title: WEB_CONF.TITLE}},
        {path: '/product', name: "product", component: Product, meta: {title: '产品中心' + WEB_CONF.TITLE_SIMP}},
        {path: '/userCenter', name: "userCenter", component: UserCenter, meta: {title: '用户中心' + WEB_CONF.TITLE_SIMP},
            children:[
                {path:'/user/login',name:'login',component:Login,meta:{title:'登陆/注册' + WEB_CONF.TITLE_SIMP}},
                {path:'/user/info',name:'userInfo',component:UserInfo,meta:{title:'用户信息' + WEB_CONF.TITLE_SIMP}},
            ]
        },
        {path: '/payCenter', name: "payCenter", component: PayCenter, meta: {title: '充值中心' + WEB_CONF.TITLE_SIMP}},
        {path: '/guardianship', name: "guardianship", component: Guardianship, meta: {title: '家长监护' + WEB_CONF.TITLE_SIMP}},
        {path: '/cooperation', name: "cooperation", component: Cooperation, meta: {title: '商务合作' + WEB_CONF.TITLE_SIMP}},
        {path: '/about', name: "about", component: About, meta: {title: '关于我们' + WEB_CONF.TITLE_SIMP}},
        {path: '/editor', name: "editor", component: Editor, meta: {title: '文本编辑' + WEB_CONF.TITLE_SIMP}},
    ]
})