<template>
  <div class="main-container">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:18,offset: 3}" :md="{span:16,offset:4}" v-for="(item,idx) in details" :key="idx" >
        <div class="product-container">
          <div class="product-icon" :style="'background-image: url(\'' + _getImgUrl(item.logo) + '\')'"></div>
          <div class="product-main">
            <h2 class="product-name">{{item.name}}</h2>
            <span class="product-channel-container">
              <a class="el-link" v-for="it in item.channel" target="_blank" :key="it" :href="(_isEmpty(it.url) ? 'javascript:void(0)' : it.url)">
              <span :class="'product-channel iconfont icon-' + it.name"></span>
            </a>
            </span>
            <p class="product-desc">{{item.desc}}</p>
            <div align="center" style="margin: 25px 0 15px 0">
              <el-button type="primary" round @click="_jumpPage(item.linkUrl)">进 入 游 戏</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "Product",
  data(){
    return {
      details:[
        {
          logo:'DuelHeroes.jpg',
          desc:'首创Roguelike和TCG融合玩法，收集卡牌，战略组卡，与全球玩家即时匹配实时对战，一起畅享对战的乐趣。数百位英雄、魔法和陷阱卡牌等待收集，创建属于自己的卡组，任意组合，万千战术变换。极速对战，一战成名，谁与争锋！一起开启Roguelike的未知冒险，体验TCG的战斗魅力！',
          name:'冒险战队（Duel Heroes）',
          linkUrl:'http://sglandcn-update.leocool.com/apk/duelheroes_googleplay-2020-12-11-14-40-59.apk',
          channel:[
            {
              name:'android',
              url:'https://play.google.com/store/apps/details?id=com.leocool.duelheroes.googleplay'
            },
            {
              name:'ios'
            }
          ]
        },{
          logo:'DonutHunter.jpg',
          desc:'超休闲射击游戏甜蜜来袭，射击收集甜甜圈，成为当之无愧的甜品猎手。游戏画风精致，几十种诱人的甜甜圈带来视觉上的冲击和心理上的疗愈；玩法简单，容易上手，通过手指拖动，即可完成操作；多种趣味射击技能，通过不断升级技能，提升射击效果；充满冒险的多场景关卡，在甜品的世界中，一起体验射击的快感！',
          name:'甜心大作战（Donut Hunter）',
          linkUrl:'http://sglandcn-update.leocool.com/apk/CookieHunter_android_release_30000_20200901183857.apk',
          channel:[
            {
              name:'android',
              url:'https://play.google.com/store/apps/details?id=com.lcool.donuthunter'
            }
          ]
        },{
          logo:'XClub.jpg',
          desc:'科幻主题模拟经营手游，暗藏未知的解密之旅！一座充满科幻色彩的赛博格大都市，各具特色的赛博格动物会进入俱乐部点餐，制作餐点招待顾客，完成日常经营。通过解锁新的菜品、升级设施等，提升俱乐部的星级。在享受模拟经营乐趣的同时，融入了解密玩法，通过不断探索，才能解开俱乐部隐藏的秘密。成为俱乐部经理人的你，能否成功守护X Club，等你来解密！',
          name:'动物之城（X Club Sim）',
          linkUrl:'https://play.google.com/store/apps/details?id=com.lcool.xlub',
          channel:[
            {
              name:'android'
            }
          ]
        },{
          logo:'MarvelDuel.jpg',
          desc:'拯救宇宙的力量就在你手中！快节奏的战略卡牌游戏，世界上最伟大的超级英雄和超级恶棍在此聚集。一股神秘的邪恶力量改变了漫威历史上最具代表性的事件，通过召唤你最喜欢的英雄，用有效的策略击败你的对手，恢复宇宙的秩序。超炫的3D视觉效果，带来身临其境的惊奇冒险体验。搭建你的卡组，组建超级英雄战队，来一场史诗级的战斗，拯救宇宙！',
          name:'漫威对决（Marvel Duel）',
          linkUrl:'https://play.google.com/store/apps/details?id=com.netease.mduelna',
          channel:[
            {
              name:'android',
            },
            {
              name:'ios'
            }
          ]
        },{
          logo:'DivinitySaga.jpg',
          desc:'诸王纷争，文明崛起！世界被战争迷雾笼罩，探索未知的广阔大陆，开拓属于自己的领地，而你就是文明的缔造者。每一次远征，都像一场智力与实力的冒险，如何进攻，如何防守，都变得更具策略性与趣味性。五大种族英雄静待召唤，传奇英雄争相登场，等候号令，期待他们在您的引领下，发挥出强大的实力！建造王国，打造属于自己的文明，成就复苏文明大业！',
          name:'洪荒文明（Divinity Saga）',
          linkUrl:'https://play.google.com/store/apps/details?id=com.wegames.cow.jp.and',
          channel:[
            {
              name:'android',
            }
          ]
        }
      ]
    }
  },
  methods:{
    _jumpPage(url){
      window.open(url)
    },
    _getImgUrl (img) {
      return require("@/assets/" + img);
    },
  }
}
</script>

<style scoped>

/*手机*/
@media screen and (max-width:768px ) {
  .main-container{
    padding: 30px 0;
  }
  .product-icon{
    width: 140px;
    height: 140px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: cover;
    margin: 30px 0 30px -70px;
    left: 50%;
    position: relative;
    z-index: 50;
  }
  .product-container{
    padding-bottom: 20px;
    margin:30px 0;
    overflow: hidden;
  }
  .product-main{
    margin: 20px 15px;
  }
  .product-name{
    font-size: 1.3em;
    text-align: center;
  }
  .product-channel-container{
    display: block;
    text-align: center;
  }
}

/*平板*/
@media screen and (max-width: 992px) and (min-width: 768.1px){
  .main-container{
    padding: 100px 0;
  }
  .product-container{
    padding: 60px 30px 20px 30px;
    margin:60px 0;
  }
  .product-icon{
    width: 160px;
    height: 160px;
    background-size: cover;
    position: absolute;
    right: 60px;
    top: -80px;
    border-radius: 20px;
  }
  .product-name{
    display: inline;
    margin-right: 40px;
  }
}

/*PC*/
@media screen and (min-width: 992.1px){
  .main-container{
    padding: 100px 0;
  }
  .product-container{
    padding: 60px 30px 20px 30px;
    margin:60px 0;
  }
  .product-icon{
    width: 160px;
    height: 160px;
    background-size: cover;
    position: absolute;
    right: 60px;
    top: -80px;
    border-radius: 20px;
  }
  .product-name{
    display: inline;
    margin-right: 40px;
  }
}

  .product-container{
    position: relative;
    -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }

  .product-channel{
    font-size: 24px;
    margin: 7px;
    color: #79806b;
  }
  .product-channel:hover{
    color: #eae01d;
  }
  .product-desc{
    word-break: normal;
    text-indent: 2em;
  }
</style>