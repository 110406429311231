<template>
  <div :style="'height:' + $store.state.routerHeight + 'px'">
    <template>
      <el-carousel :interval="5000" autoplay loop height="100%">
        <el-carousel-item v-for="item in 3" :key="item">
          <div :class="'carousel-pic pic-' + item"></div>
        </el-carousel-item>
      </el-carousel>
    </template>

  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return {

    }
  },
}
</script>

<style scoped>
/*手机*/
@media screen and (max-width:768px ) {
  .pic-1{
    background-image: url("../assets/hlsg_m.jpg");
    -moz-background-image:url("../assets/hlsg_m.jpg");
    -o-background-image:url("../assets/hlsg_m.jpg");
    -webkit-background-image:url("../assets/hlsg_m.jpg");
  }
  .pic-2{
    background-image: url("../assets/jdxy_m.jpg");
    -moz-background-image:url("../assets/jdxy_m.jpg");
    -o-background-image:url("../assets/jdxy_m.jpg");
    -webkit-background-image:url("../assets/jdxy_m.jpg");
  }
  .pic-3{
    background-image: url("../assets/sywm_m.jpg");
    -moz-background-image:  url("../assets/sywm_m.jpg");
    -o-background-image:  url("../assets/sywm_m.jpg");
    -webkit-background-image:  url("../assets/sywm_m.jpg");
  }

}
/*PC*/
@media screen and (min-width: 992.1px){
  .pic-1{
    background-image: url("../assets/hlsg.jpg");
    -moz-background-image: url("../assets/sywm.jpg");
    -o-background-image: url("../assets/sywm.jpg");
    -webkit-background-image: url("../assets/sywm.jpg");
  }
  .pic-2{
    background-image: url("../assets/jdxy.jpg");
    -moz-background-image: url("../assets/jdxy.jpg");
    -o-background-image: url("../assets/jdxy.jpg");
    -webkit-background-image: url("../assets/jdxy.jpg");
  }
  .pic-3{
    background-image: url("../assets/sywm.jpg");
    -moz-background-image: url("../assets/sywm.jpg");
    -o-background-image: url("../assets/sywm.jpg");
    -webkit-background-image: url("../assets/sywm.jpg");
  }

}
  .carousel-pic{
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .el-carousel{
    height: 100%;
  }

</style>