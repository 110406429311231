import Vue from 'vue'
import Vuex from "vuex"
import VuexPersistence from 'vuex-persistedstate'

Vue.use(Vuex)

/**
 * 存放公共数据，子组件均可以访问到
 * @type {{isAuthenticated: boolean, user: {}, url: string}}
 */
const state = {
    isAuthenticated: false,  // 是否认证
    // host: 'http://localhost:8099',
    host: '',
    token: '',
    user:{},
    routerHeight:0
}

/**
 * Getter 用来在获取数据源的时候，对数据源进行一定的加工后再返回
 * @type {any}
 */
const getters = {
    isAuthenticated (state){
        if (state.isAuthenticated){
            if (state.token != null && state.token.trim() !== ''){
                return true
            }
        }
        return false
    },
    getToken: state => state.token,
    getSocketAddress: state => state.socketAddress,
}

/**
 * 对数据仓中的数据，进行修改。Mutation只支持同步方法
 *
 * 调用方法：this.$store.commit()
 *
 * @type {{}}
 */
const mutations = {
    setRouterHeight(state,height){
        state.routerHeight = height
    },
    setLoginState(state,data){
        state.isAuthenticated = true
        state.token = data.token
        state.user = data.user
    },
    clearLogStatus(state){
        state.isAuthenticated = false
        state.token = ''
        state.user = {}
    }
}

/**
 * Action和Mutation类似，他们区别之处在于：
 * 1、Action 提交的是 mutation，而不是直接变更状态。
 * 2、Action 可以包含任意异步操作。
 *
 * 调用方法：this.$store.dispatch()
 *
 * @type {{setIsAuthenticated: actions.setIsAuthenticated, clearCurrentState: actions.clearCurrentState, setUser: actions.setUser}}
 */
const actions = {

}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    plugins: [VuexPersistence()]
})
