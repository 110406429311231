<template>
  <div :style="'min-height:' + ($store.state.routerHeight-200) + 'px;'">
    <el-row style="margin:30px 0;">
      <el-col :xs="{span:20,offset:2}" :sm="{span:16,offset:4}" :md="{span:12,offset:6}">
        <div class="info-header">关于烈酷</div>
        <div v-html="content" class="info-body"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "About",
  data(){
    return {
      content:'<p><b>杭州烈酷科技有限公司</b>成立于2013年，是一家以移动游戏研发与运营为主体的创新创业型企业， 由移动互联网资深研发团队创立。“科技+文化+创意”是我们的理念，“创新、激情、分享” 是我们的文化，“快乐工作、快乐生活”是我们的追求。我们拥有一支富有创造力和前瞻力、怀有激情和梦想的高素质团队，共同追求梦想，创造快乐人生！<br></p><p><o:p></o:p></p><p>公司没有复杂的管理制度，没有领导和下级，只有老师和同学、只有团队成员。技术、经验很重要，但绝不是最重要的，我们更看重一个人的品质，是不是正直、真诚、踏实、好学，是不是可以和团队一起并肩作战、打拼事业、分享成功、创造辉煌。<o:p></o:p></p><p><br></p><p>我们的目标是：<o:p></o:p></p><p>让更多人使用我们的产品；<o:p></o:p></p><p>让我们的用户享受更便捷的生活；<o:p></o:p></p><p>让我们的团队成员实现自己的价值。</p>'
    }
  }
}
</script>

<style scoped>
  .info-header{
    border-bottom: 1px solid rgb(198, 179, 179);
    padding: 30px 0;
    font-weight: 900;
    margin-bottom: 55px;
    text-align: center;
    letter-spacing: 20px;
    font-size: 30px;
    color: #efa111;
  }
</style>
