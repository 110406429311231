<template>
  <div :style="'min-height:' + $store.state.routerHeight + 'px'">
    <el-row>
      <el-col :xs="{span:22,offset:1}" :sm="{span:16,offset:4}" :md="{span:12,offset:6}">
        <el-row style="margin:30px 0">
          <el-col>
            <el-steps :active="1" simple>
              <el-step title="1. 输入充值金额"></el-step>
              <el-step title="2. 确认充值信息"></el-step>
              <el-step title="3. 进入第三方支付"></el-step>
            </el-steps>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form ref="payForm" :model="payForm" :rules="rules" label-width="120px" style="margin: 20px 0 50px 0">
              <el-form-item label="通行证账号" prop="account">
                <el-input v-model="payForm.account"></el-input>
              </el-form-item>
              <el-form-item label="确认账号" prop="account_re">
                <el-input v-model="payForm.account_re"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="telephone">
                <el-input v-model="payForm.telephone"></el-input>
              </el-form-item>
              <el-form-item label="充值游戏" prop="game">
                <el-select v-model="payForm.game">
                  <el-option v-for="item in games" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-card class="banks">
                <div slot="header">
                  <span>网上银行支付</span>
                </div>
                <el-form-item prop="bank" label-width="0">
                  <el-radio-group v-model="payForm.bank">
                    <el-radio v-for="item in banks" :key="item" :label="item"><el-image :src="_getImgUrl(item)"></el-image></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-card>
              <el-form-item label="充值金额" prop="amount">
                <el-input v-model="payForm.amount" type="number" placeholder="兑换比例：1元 = 1钻石"></el-input>
              </el-form-item>
              <div align="center">
                <el-button type="primary" round style="width: 200px" @click="_commitForm('payForm')">下一步</el-button>
              </div>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PayCenter",
  data(){
    return {
      payForm:{
        account:'',
        account_re:'',
        telephone:'',
        game:'',
        bank:'',
        amount:''
      },
      games:[
          "甜心大作战（Donut Hunter）",
          "动物之城（X Club Sim）",
          "冒险战队（Duel Heroes）",
          "漫威对决（Marvel Duel）",
          "洪荒文明（Divinity Saga）"
      ],
      banks:[
        'bank/zggsyh.png',
        'bank/zggdyh.png',
        'bank/jtyh.png',
        'bank/zgjsyh.png',
        'bank/zgnyyh.png',
        'bank/zgyh.png',
        'bank/zgyz.png',
        'bank/zsyh.png',
      ],
      rules:{
        account:[{required:true,message:'请填写通行证账号',trigger:'change'}],
        account_re:[
            {required:true,message:'请再次填写通行证账号',trigger:'change'},
            {validator:(rule,value,callback)=>{
                if (value !== this.payForm.account){
                  callback(new Error("两次账号输入不一致"))
                }else{
                  callback()
                }
              },trigger:'change'},
        ],
        telephone:[{required:true,message:'请填写手机号码',trigger:'change'}],
        game:[{required:true,message:'请选择要充值的游戏',trigger:'change'}],
        bank:[{required:true,message:'请选择充值银行',trigger:'change'}],
        amount:[
          {required:true,message:'请输入金额',trigger:'change'},
          {validator:(rule,value,callback)=>{
              if (value <= 0){
                callback(new Error("充值金额不正确"))
              }else{
                callback()
              }
            },trigger:'change'},

        ],
      }
    }
  },
  methods:{
    _getImgUrl (img) {
      return require("@/assets/" + img);
    },
    _commitForm(formName){
      this.$refs[formName].validate((valid)=>{
        if (valid){
          this._alertError("功能测试中，获得营运资质后开启...")
        }
      })
    }
  }
}
</script>

<style scoped>
  .banks .el-radio{
    margin: 20px;
    border: 1px solid #ffe2e2;
    padding: 15px 0 15px 10px;
    width: 210px;
  }
  .banks{
    margin: 20px 0;
  }
  .el-select{
    width: 100%;
  }
</style>
<style>
  .banks .el-radio__input{
    margin-top: -26px !important;
  }
</style>