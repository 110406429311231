<template>
  <div id="app">

    <div ref="elMenuHeader" id="elMenuHeader">
      <el-menu
          router
          :default-active="activePage"
          mode="horizontal"
          background-color="#545c64"
          style="border: none;"
          text-color="#fff"
          @select="_handleSelectMenu"
          active-text-color="#ffd04b">
        <div class="header">
          <img src="./assets/logo.png" class="header_logo" alt="Leocool games">
          <i class="header-menu-icon hidden-md-and-up el-icon-more" @click="_turnAside"></i>
          <transition name="custom-classes-transition"
                      enter-active-class="animated slideInRight"
                      leave-active-class="animated slideOutRight">
            <div class="header_menu" v-show="screenWidth.cur > screenWidth.phone || showAside">
              <i class="el-icon-close hidden-md-and-up close-icon" @click="_turnAside"></i>
              <ul>
                <el-menu-item v-for="(item,idx) in headerLink" :key="idx" :index="item.index">{{item.name}}</el-menu-item>
              </ul>
            </div>
          </transition>
          <transition name="custom-classes-transition"
                      enter-active-class="animated fadeIn"
                      leave-active-class="animated fadeOut">
            <div class="header-mask hidden-md-and-up" @click="_turnAside" v-show="this.showAside"></div>
          </transition>
        </div>
      </el-menu>
    </div>


    <div class="router-body">
      <transition name="move" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <div class="footer">
      <div class="footer-info">
        <el-row>
          <el-col :xs="{span:22,offset:1}" :sm="{span:20,offset: 2}" :md="{span:18,offset:3}">
            <el-row>
              <el-col :xs="24" :sm="8" :md="8" class="footer-info-item">
                <h3>关于烈酷</h3>
                <p>{{footerInfo.about}}</p>
              </el-col>
              <el-col :xs="24" :sm="8" :md="{span:6,offset:2}" class="footer-info-item">
                <h3>快速访问</h3>
                <ul class="quick-assets">
                  <router-link v-for="(item,idx) in headerLink" :key="idx" tag="li" :to="item.index">
                    <a>{{item.name}}</a>
                  </router-link>
                </ul>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" class="footer-info-item">
                <h3>联系信息</h3>
                <p>{{footerInfo.contact.loc}}</p>
                <div>
                  <i class="el-icon-phone-outline footer-icon"></i>
                  {{footerInfo.contact.ele}}
                </div>
                <div>
                  <i class="el-icon-message footer-icon"></i>
                  {{footerInfo.contact.email}}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </div>
      <div class="copyright">
        <div class="copy-info">{{copyInfo.copyright}}</div>
        <div class="copy-icp">
          <a style="margin-right: 20px" target="_blank" :href="copyInfo.icpNumberLink">{{copyInfo.icpNumber}}</a>
          <img src="./assets/ba.png">
          <a :href="copyInfo.icpLink" target="_blank">{{copyInfo.icpInfo}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
        activePage:'/index',
        showAside:false,
        headerLink:[
          {
            name:'首页',
            index:'/index'
          },{
            name:'产品中心',
            index:'/product'
          },{
            name:'用户服务',
            index:'/userCenter'
          },
          // {
          //   name:'充值中心',
          //   index:'/payCenter'
          // },
          {
            name:'家长监护',
            index:'/guardianship'
          },{
            name:'商务合作',
            index:'/cooperation'
          },{
            name:'关于我们',
            index:'/about'
          },
        ],
        screenWidth:{
          phone:768,
          pad:992,
          cur:0
        },
        footerInfo:{
          about:'杭州烈酷科技有限公司成立于2013年，是一家以移动游戏研发与运营为主体的创新创业型企业， 由移动互联网资深研发团队创立。“科技+文化+创意”是我们的理念，“创新、激情、分享” 是我们的文化，“快乐工作、快乐生活”是我们的追求。我们拥有一支富有创造力和前瞻力、怀有激情和梦想的高素质团队，共同追求梦想，创造快乐人生！',
          contact:{
            loc:'浙江省杭州市西湖区城市发展大厦1208',
            ele:'0571-81110357',
            email:'service@leocool.com'
          }
        },
        copyInfo:{
          copyright: 'Copyright©杭州烈酷科技有限公司 2020',
          icpInfo:'浙公网安备 33010802011333号',
          icpNumber:'浙ICP备14000835号',
          icpLink:'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011333',
          icpNumberLink:'http://beian.miit.gov.cn/',
        }
    }
  },
  mounted() {
    this._initPath(this.$route)
    this.screenWidth.cur = document.body.clientWidth;
    let headerE = document.getElementById('elMenuHeader')
    this.$store.commit('setRouterHeight',document.body.clientHeight - (headerE.offsetHeight === 0 ? 93 : headerE.offsetHeight))
    window.onresize = () => {
      return (() => {
        this.screenWidth.cur = document.body.clientWidth;
        let headerE = document.getElementById('elMenuHeader')
        this.$store.commit('setRouterHeight',document.body.clientHeight - headerE.offsetHeight)
      })();
    };
  },
  watch:{
    $route(to) {
      this._initPath(to)
    }
  },
  methods:{
    _turnAside(){
      if (document.body.offsetWidth <= this.screenWidth.phone){
        this.showAside = !this.showAside
      }
    },
    _initPath(route){
      for (let i in this.headerLink){
        if (route.path === this.headerLink[i].index){
          this.activePage = route.path
          break
        }else if (route.name === 'login' || route.name === 'userInfo'){
          this.activePage = '/userCenter'
          break
        }
      }
    },
    _handleSelectMenu(key){
      if (this.activePage !== key){
        this._turnAside()
      }
    }
  }
}
</script>

<style scoped>

  /*手机*/
  @media screen and (max-width:768px ) {
    .header_menu{
      position: fixed;
      width: 200px;
      height: 100%;
      right: 0;
      background-color: #545c64;
      z-index: 1000;
    }
    .header_menu .close-icon{
      color: white;
      font-size: 25px;
      margin-left: 5px;
      margin-top: 5px;
    }
    .header_menu li{
      text-align: center;
    }
    .header_logo{
      width: 170px;
      margin: 10px 5px;
    }
    .footer-info{
      padding: 50px 0;
    }
  }

  /*平板*/
  @media screen and (max-width: 992px) and (min-width: 768.1px){
    .header_menu{
      position: relative;
    }
    .footer-info{
      padding: 70px 0;
    }
  }

  /*PC*/
  @media screen and (min-width: 992.1px){
    .header_menu{
      position: relative;
      float: right;
      width: 720px;
    }
    .header_menu li{
      float: left;
      padding: 0 20px;
      list-style: none;
      cursor: pointer;
      position: relative;
      transition: border-color .3s,background-color .3s,color .3s;
      box-sizing: border-box;
      white-space: nowrap;
      line-height: 90px;
      height: 100%;
    }
    .footer-info{
      padding: 70px 0;
    }
  }

  .header{
    padding: 0 15px;
  }

  .header-menu-icon{
    float: right;
    color: white;
    font-size: 25px;
    margin: 30px 5px;
  }

  .header_menu ul{
    padding: 0;
  }

  .header_menu ul,.header_menu li{
    list-style: none;
    margin: 0;
  }

  .header_logo{
    float: left;
  }

  .header-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.3);
  }

  .footer{
    background-color: #1c1e21;
    min-height: 500px;
  }
  .footer-info{
    background-color: #303133;
    color: #bcb4b4;
  }
  .footer-info h3{
    color: white;
  }
  .footer-info p{
    word-wrap: break-word;
    word-break: break-all;
  }
  .footer-info-item{
    padding: 15px;
  }
  .quick-assets{
    list-style: none;
    margin:0;
    padding: 0;
  }
  .quick-assets li{
    margin: 10px 0;
  }
  .quick-assets a{
    text-decoration: none;
    color: #bcb4b4;
    font-size: 16px;
  }
  .quick-assets a:hover{
    color: rgb(255, 208, 75);
  }
  .footer-icon{
    color: whitesmoke;
    margin-right: 20px;
  }
  .copyright{
    text-align: center;
    padding: 50px 0;
    color: #bfb4b4;
    font-size: 14px;
  }
  .copy-info{
    margin-bottom: 15px;
    color: whitesmoke;
  }
  .copy-icp a{
    text-decoration: none;
    color: #bfb4b4;
  }
  .copy-icp a:hover{
    color: whitesmoke;
  }
</style>

<style>
:focus{
  outline: none;
}
body,html{
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
