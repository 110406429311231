<template>
  <div>
    <el-row style="margin:50px 0">
      <el-col :xs="{span:20,offset:2}" :sm="{span:16,offset:4}" :md="{span:12,offset:6}">
        <el-form ref="registerForm" label-width="100px" :model="registerForm" label-position="left">
          <el-form-item label="账号" prop="account">
            <el-input v-model="registerForm.account" placeholder="请输入登陆账号"></el-input>
          </el-form-item>
          <el-form-item label="登陆邮箱" prop="emailAccount">
            <el-input type="text" v-model="registerForm.emailAccount" placeholder="用于登陆的邮箱"></el-input>
          </el-form-item>
          <el-form-item label="安全邮箱" prop="emailRecovery">
            <el-input type="text" v-model="registerForm.emailRecovery" placeholder="该邮箱是找回账号密码的重要依据"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realName">
            <el-input type="text" v-model="registerForm.realName" placeholder="请输入您的真实姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input type="text" v-model="registerForm.idCard" placeholder="请输入您的身份证号码"></el-input>
          </el-form-item>
          <el-form-item label="安全问题" prop="question">
            <el-select v-model="registerForm.question" placeholder="请选择安全问题" disabled>
              <el-option
                  v-for="item in questions"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问题答案" prop="answer" v-if="registerForm.question !== '无'">
            <el-input type="textarea" v-model="registerForm.answer" placeholder="请输入安全问题答案"></el-input>
          </el-form-item>
          <el-form-item>

          </el-form-item>
          <div align="center">
            <el-button type="success" round style="width: 120px;margin:15px" @click="_updateInfo('registerForm')">修 改 信 息</el-button>
            <el-button type="danger" round style="width: 120px;margin:15px" @click="_resetPwd('registerForm')">重 置 密 码</el-button>
            <el-button round style="width: 120px;margin:15px" @click="_logout()">注 销</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  data(){
    return {
      registerForm:{
        account:'',
        emailRecovery:'',
        realName:'',
        idCard:'',
        emailAccount:'',
        question:'',
        answer:'',
      },
      questions:[
        '无',
        '你在哪里上的小学？',
        '你的家在哪里？',
        '你妈妈的生日是哪天？',
        '你的初中语文老师是谁？',
        '你12岁时你父亲多少岁？',
      ],
    }
  },
  mounted() {
    this.registerForm = this.$store.state.user
  },
  methods:{
    _updateInfo(formName){
      console.log(formName)
    },
    _resetPwd(formName){
      console.log(formName)
    },
    _logout(){
      this.$store.commit('clearLogStatus')
      this.$router.push('/user/login')
    }
  }
}
</script>

<style scoped>

</style>