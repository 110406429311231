<template>
  <div>
    <el-row class="main-container" style="margin:100px 0;">
      <el-col :xs="{span:22,offset:1}" :sm="{span:20,offset: 2}" :md="{span:18,offset:3}">
        <el-row>
          <el-col :xs="24" :sm="11" :md="11">
            <el-card class="info-box">
              <h2 style="color: #efa111;">游戏发行</h2>
              <div>联系人：陈先生</div>
              <div>邮箱：<a href="mailto:bd@leocooljoy.com" style="color: #efa111;">bd@leocooljoy.com</a></div>
              <div style="margin: 20px 0; font-weight: 600; font-size: 19px;color: rgb(162 161 118);border-left: 4px solid;padding-left: 10px;">合作方向：</div>
              <p>
                烈酷科技集结国内顶尖的技术人才、营销人才，拥有丰富的互联网从业经验、掌握核心的营销方法和营销渠道。愿与您一起打造优质的移动互联应用，共创双赢局面！
              </p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="{span:11,offset:2}" :md="{span:11,offset:2}">
            <div class="map-container">
              <el-amap vid="loc" :zoom="mapConf.zoom" :center="mapConf.center">
                <el-amap-marker :position="mapConf.position"></el-amap-marker>
                <el-amap-info-window :position="mapConf.position" :content="mapConf.content"></el-amap-info-window>
              </el-amap>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Cooperation",
  data(){
    return {
      mapInstance:{},
      mapConf:{
        zoom:20,
        center:[120.095366,30.309940],
        position:[120.095366,30.309940],
        infoPos:[120.101614,30.316217],
        content:"<h4>杭州烈酷科技有限公司</h4> <p>地址：浙江省杭州市西湖区城市发展大厦1208</p><p>电话：0571-81110357</p>",
        open:'11'
      }
    }
  }
}
</script>

<style scoped>
/*手机*/
@media screen and (max-width:768px ) {
  .map-container{
    height: 350px;
  }
  .main-container{
    margin:20px 0 30px 0;
  }
  .info-box{
    margin-bottom: 60px;
  }
}

/*平板*/
@media screen and (max-width: 992px) and (min-width: 768.1px){
  .map-container{
    height: 400px;
  }
  .main-container{
    margin:100px 0;
  }
  .info-box{
    margin-top: 100px;
  }
}

/*PC*/
@media screen and (min-width: 992.1px){
  .map-container{
    height: 500px;
  }
  .main-container{
    margin:100px 0;
  }
  .info-box{
    margin-top: 100px;
  }
}

</style>
<style>
  .amap-info-content{
    color: white;
    background-color: #ec7171;
    padding: 30px;
  }
</style>