<template>
  <div style="padding: 100px 0">
    <el-row>
      <el-col :span="16" :offset="4">
        <div ref="editorElem" style="text-align:left;"></div>
        <div style="margin: 15px 0">
          <el-button type="success" @click="_doCopy(editorContent)">复制html</el-button>
        </div>
        <div style="margin-top: 50px">
          {{editorContent}}
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import E from "wangeditor";

export default {
  name: "Editor",
  data() {
    return {
      editor: null,
      editorContent: ''
    };
  },
  watch:{
    'editor.txt':{
      handler(val){
        if (val != null){
          let html = val.html()
          if (html !== this.editorContent){
            this.editorContent = html
          }
        }
      },
      deep:true,
      immediate:true
    }
  },
  mounted() {
    this.editor = new E(this.$refs.editorElem);
    this.editor.create()
  },
  methods:{
    _doCopy(val){
      let that = this
      this.$copyText(val).then(function () {
        that._notifySuccess('复制成功！')
      }, function (e) {
        that._notifyError('复制失败' + e)
      })
    }
  }
}
</script>

<style scoped>

</style>